//scroll bar styled
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar:hover {
  height: 10px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(162, 162, 162);
}
