@import '../../styles/variables';

.page {
  * {
    font-size: 14px !important;
  }
  background-color: #fff;
  :global {
    .ant-checkbox {
      margin: 0px 5px 0px 0px !important;
    }
    .ant-checkbox-inner {
      width: 14px !important;
      height: 14px !important;
      border-color: $warning;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $warning !important;
      border-color: $warning !important;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $warning !important;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: $warning !important;
    }
    .ant-card {
      font-size: 0.75rem !important;
    }
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item-required {
      font-size: 20px;
      height: 32px;
    }
    .ant-form-item-label {
      white-space: normal !important;
    }
    .ant-form-item-label > label {
      display: inline !important;
    }
    .ant-table {
      line-height: 1 !important;
      td {
        padding: 4px 8px !important;
      }
      th {
        padding: 10px !important;
      }
    }
    .ant-table-thead > tr > th {
      text-align: center !important;
    }
  }
}

.container {
  margin: 1rem;
  padding: 10px;
  h2 {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    text-align: center;
  }
  h6 {
    margin-top: 8px;
    margin-bottom: 16px;
    text-align: center;
    font-style: italic;
  }
}

.ant-select {
  height: 100%;
  .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    align-self: center;
    margin: 0;
  }
}

.footer {
  overflow: hidden;
  position: relative;
  padding: 20px;
  text-align: center;
  button {
    margin: 5px;
    padding: 0 5rem;
    border: none;
  }
  &:before {
    content: '';
    position: absolute;
    border-top: 7.5px dashed #afafaf;
    top: -6px;
    bottom: -8px;
    left: 0px;
    right: 0px;
  }
}

.inputField {
  display: flex !important;
  justify-content: space-between;
  :last-child {
    border-right-width: 1px !important;
  }
  a {
    color: #7c9dce;
    font-style: italic;
  }
  :global {
    .ant-input-number {
      border-right-width: 0;
      border-radius: 0;
    }
  }
}
