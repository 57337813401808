.ant-select-dropdown {
  min-width: 150px !important;
}

.ant-select-selection {
  min-width: max-content !important;
}

.ant-select-item-group {
  color: $secondary-color;
  font-weight: bold;
}

.ant-select-item {
  font-size: 12px;
}
