.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
  top: 60% !important;
  right: -41px !important;
  min-width: 253px !important;
  transform: translateY(-42%) !important;
}

.apexcharts-legend-marker {
  border-radius: 0px !important;
  top: 2px !important;
  margin-right: 6px !important;
}

.apexcharts-legend {
  padding: 0px !important;
}

.apexcharts-canvas {
  width: 100% !important;
}

.horizontal-chart .apexcharts-svg {
  // background-color: green !important;
  position: absolute;
  left: -34px;
}
