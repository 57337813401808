@import '../../styles/variables';

.fileUpload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  :global {
    .anticon-eye {
      color: $light-cyan;
    }
    .anticon-delete {
      color: $danger;
    }
    .ant-space-item:last-child {
      width: 100% !important;
    }
  }
}

.uploadDiv {
  margin-right: 4px;
  button {
    border-style: dashed;
  }
}

.pdfViewContainer {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  display: flex;
}
