@import '../../styles/variables';

.btnSystemInstructions {
  text-align: center;
  :global {
    .ant-btn {
      background-color: darken(#ff5d5e, 25%) !important;
      border: none;
      font-weight: bold;
      width: 230px;
      border-radius: 16px !important;
      white-space: pre-wrap;
      height: auto;
      line-height: 24px;
      padding: 10px !important;
      &:hover {
        background: darken(#ff5d5e, 10%) !important;
        // animation-name: elementor-animation-pulse;
        // animation-duration: 1s;
        // animation-timing-function: linear;
        // animation-iteration-count: infinite;
      }
    }
  }
}

.successIcon {
  margin-left: 10px;
  color: #52c41a;
}

.CheckInformationStyle {
  padding: (48px + $header-height) 10% 48px;
  height: 100vh;
  & > div {
    height: 100%;
  }
  :global {
    .ant-btn-link {
      color: $primary-color !important;
      font-weight: 500;
    }
  }
}

.register {
  padding: 0 24px;
  input {
    font-weight: bold;
  }
  :global {
    .ant-btn-primary {
      border-color: transparent !important;
    }
    .ant-select-selector,
    .ant-input {
      border-right-color: transparent !important;
      border-top-width: 0 !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
      background: transparent !important;
      border-radius: 0 !important;
      &:hover,
      &:focused {
        border-top-width: 0 !important;
        border-left-width: 0 !important;
        border-right-width: 0 !important;
      }
    }
    .ant-select:hover .ant-select-selector {
      border-right-width: 0 !important;
    }
    .ant-select-focused .ant-select-selector,
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused
      .ant-select-selector,
    .ant-input:focus {
      box-shadow: none !important;
      border-right-width: 0 !important;
      border-bottom-width: 2px !important;
    }
    .ant-input:placeholder-shown {
      font-weight: normal;
    }
  }
  button {
    width: 200px;
  }
}

.info {
  align-items: center;
  display: grid;
  height: 100%;
  & > div {
    padding: 16px 0;
  }
  & div:last-child {
    margin-bottom: 20px;
  }
  .logo {
    display: flex;
    align-items: center;
    .logoName {
      font-weight: bold;
      font-size: 18px;
      color: $dark-blue;
      margin-left: 12px;
    }
  }
  :global {
    .anticon {
      margin-right: 8px;
    }
  }
}

.orgChooseDiv {
  padding: 0 10px;
}

@media (max-width: 1200px) {
  .CheckInformationStyle {
    height: auto;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
}