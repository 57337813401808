.ant-btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  &:hover,
  &:focus {
    background-color: $primary-hover !important;
  }
}

.ant-btn-secondary {
  &:hover,
  &:focus {
    color: $primary-color;
    border-color: $primary-color;
  }
  .anticon {
    color: $primary-color;
  }
}

.ant-btn-link {
  color: $secondary-color;
  .anticon {
    color: $secondary-color;
  }
}

.ant-btn:empty {
  display: none;
}