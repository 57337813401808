.ant-modal-header {
  background: $primary-color !important;
  padding: 9px 24px !important;
}

.ant-modal-title {
  color: white !important ;
}

.ant-modal-close-x {
  line-height: 40px !important;
  color: white !important;
}

.org-modal .ant-modal-close-x {
  color: inherit !important;
}

.modal-footer-btn {
  text-align: right;
  margin-top: 16px;
}
