.ant-layout-header {
  display: flex;
  padding: 0px 10px !important;
  color: white !important;
  background: $primary-color !important;
  height: $header-height !important;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  .top-menu-link {
    display: flex;
    align-items: center;
    position: fixed;
    right: 0;
  }
  .list-inline-item {
    padding: 0 1rem;
    height: $header-height;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color: white;
    cursor: pointer;
    .avatar-text {
      margin-left: 0.5rem;
      color: white;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .helpText {
    color: white;
    cursor: pointer;
  }
}

.ant-layout-content {
  background-color: white;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  min-height: 100vh !important;
}

.ant-layout-sider {
  z-index: 1000;
  background: $hover-light !important;
}

.ant-layout-sider-children {
  position: fixed;
  width: inherit;
  box-shadow: 0px 0px 5px #babcc1;
  overflow: auto;
  background-color: $hover-light;
}

.ant-badge-count {
  top: 8px;
  right: 8px;
}

.header-icon-right {
  font-size: 15px;
}

.admin-full-sider {
  display: block;
}

.menu-show {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

.menu-drawer {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .menu-show {
    display: block;
  }
  .header-icon-right {
    display: none;
  }
  .admin-full-sider {
    display: none;
  }
  .letter-top {
    display: none !important;
  }
  .help-top {
    display: none !important;
  }
}
