@import '../../../../../styles/variables';

.content {
  width: 100%;
  :global {
    .ant-space-item:last-child {
      width: 100% !important;
    }
    .anticon {
      font-size: 18px !important;
    }
  }
}

.iconRound {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: grid;
  align-content: center;
  color: white;
}

.successBg {
  background-color: $success;
}

.infoBg {
  background-color: $info;
}

.warningBg {
  background-color: $warning;
}

.dangerBg {
  background-color: $danger;
}

.warningTime {
  color: $light-grey;
  font-size: 13px;
  .date {
    font-size: 11px;
    width: 100px;
    margin-left: 16px;
  }
}

.requestListDiv {
  :global {
    .ant-list-item:hover {
      box-shadow: 0 0 10px #eee !important;
    }
    .ant-list-split .ant-list-item {
      background: #fdfdfd;
    }
  }
}
