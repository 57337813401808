.counter {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #363636d1;
  border-radius: 10px 0px 0px;
  font-size: 10px;
  padding: 2px 10px;
  :global {
    .ant-statistic-content {
      font-size: 14px;
      color: white;
    }
  }
}
