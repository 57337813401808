.ant-form-item-label {
  white-space: normal !important;
}

.ant-form-item-label > label {
  display: inline !important;
}

.ant-form-item-extra {
  font-style: italic;
  margin-top: 4px;
}

.changed-field {
  display: flex;
  border: 1.5px solid orangered;
  border-radius: 4px;
  padding: 4px;
}

.require-label {
  label::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
