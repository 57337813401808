body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  padding: $header-height + 10px 20px 20px 20px;
  min-width: 500px;
}

.btn-light {
  background: $btn-light !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.form-check {
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: $dark-blue;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.subtitle {
  background-color: $primary-color;
  color: #fff;
  padding: 6px;
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  font-weight: bold !important;
  margin: 30px 0;
}

.form-input {
  text-align: center;
  padding: 2px !important;
  .ant-input-number-input {
    padding: 0 !important;
  }
}

.app-header-dropdown {
  border-radius: 6px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.icontext {
  align-items: center;
  display: flex;
  margin: 0px;
  background-color: white;
}

.icon {
  color: $color-grey;
  size: 1px;
  padding: 0px;
  margin: '8px';
}

.search-button .ant-input {
  background: whitesmoke !important;
}

//hynax table
.Hynax-table {
  width: 100%;
  overflow: auto;
  margin-top: 4px;
  .ant-table-thead > tr > th {
    text-align: center !important;
    background: white !important;
  }
  .pi {
    font-weight: 100;
    font-size: small;
  }
}

.sub-group {
  margin-top: -10px;
  background: white;
  width: fit-content;
  padding: 0 6px;
}

input {
  width: 100%;
  height: fit-content;
}

.ant-input:placeholder-shown,
.ant-select-selection-placeholder,
.ant-picker-input > input:placeholder-shown {
  font-style: italic;
}

.ant-input-disabled,
.ant-input-number-disabled,
.ant-picker-disabled,
.ant-select-disabled .ant-select-selector,
.ant-input[disabled],
.ant-input-number[disabled],
.form-input[disabled] {
  background: transparent !important;
  color: #00000080 !important;
}

.require::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.btn-layout {
  margin: 10px 0;
}

.btn-layout-bottom {
  margin: 10px 0;
  text-align: right;
  width: 100%;
  justify-content: right;
}

//Busadmedeelel styling
.button-col-header-save {
  align-items: center;
  display: flex;
  font-size: 14px;
  background-color: white;
}

.button-col-headers-icon {
  font-size: 14px;
  color: $primary-color;
}

.admin-sider-style {
  background: white !important;
  .name {
    font-weight: bold;
    padding: 0px 0px 0px px;
    font-size: 18px;
    color: $secondary-color;
  }
  .MuiSvgIcon-root.ant-menu-item-icon {
    color: $light-grey !important;
    font-size: 12px;
  }
  .image {
    width: 75px;
    height: 75px;
    padding: 8px;
    margin: 8px;
  }
}

.permission {
  p {
    font-style: italic;
  }
  .ant-tree {
    height: calc(100% - 80px);
    overflow: auto;
  }
}

//baiguullaProfile_Styling

.auto-select {
  font-size: 14px;
  .anticon {
    color: #01bcd4 !important;
    font-size: 14px !important;
    margin-top: 0px !important;
  }
}

.text-compans {
  text-align: left;
  font-weight: 600;
  overflow-wrap: break-word;
  .ant-input {
    font-size: 13px !important;
  }
  .ant-tag {
    margin-bottom: 4px;
  }
}

.placeholders {
  color: #b1b1b1;
  font-weight: normal;
}

.info-modal {
  text-align: center !important;
  p {
    margin-top: 14px;
    margin-bottom: 0;
    font-weight: 600;
  }
  .anticon-close {
    color: $light-grey;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
    font-size: 9px;
    &:hover {
      color: $color-grey;
    }
  }
  .ant-modal-confirm-btns {
    float: none !important;
    text-align: center;
  }
}

//Tailan hesgiin css
.GeneralInfoTabBigDiv {
  .ant-divider-horizontal {
    margin: 0 !important;
    width: 0;
  }
  .ant-form-item {
    margin-bottom: 4px !important;
  }
}

.logIcon {
  font-size: 16px;
  color: #3d9ff3;
  transition: all 0.2s;
  &:hover {
    font-size: 17px;
  }
}

.deleteIcon {
  color: $danger;
  &:hover {
    color: darken($danger, 10%);
  }
}

//Portal xesgiin scss

.modalImageUploadDiv {
  text-align: -webkit-center;
}

.helpIcon {
  height: 30px;
  width: 30px;
  padding-right: 8px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    padding-right: 5px;
  }
}

.ant-tag {
  white-space: pre-wrap;
}

.ant-tabs-nav::before,
.ant-divider-horizontal.ant-divider-with-text {
  border-color: $border-primary-color !important;
}

.ant-table.ant-table-small {
  font-size: 12px;
}

.season {
  background-color: #98c8ff36 !important;
}
