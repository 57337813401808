.p-component {
  font-size: 14px !important;
}

.p-inputtext,
.p-multiselect .p-multiselect-label {
  padding: 0 0.5rem !important;
}

.p-datatable,
.p-treetable {
  font-size: 12px !important;
  text-align: center !important;
  tr:nth-child(even) {
    background: #f8f9fa !important;
  }
  .p-datatable-thead > tr > th,
  .p-treetable-thead > tr > th {
    text-align: center !important;
    padding: 10px !important;
  }
  .p-datatable-tbody > tr > td,
  .p-treetable-tbody > tr > td {
    padding: 4px 8px !important;
    cursor: pointer;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 10px;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 20px !important;
  height: 20px !important;
}

.p-rowgroup-header-name {
  display: inline-flex;
  width: calc(100% - 20px);
  padding-left: 10px;
}

.p-checkbox .p-checkbox-box {
  width: 15px !important;
  height: 15px !important;
}

.p-inputtext.p-component.p-column-filter {
  height: 23px !important;
}

.p-rowgroup-header {
  font-weight: bold;
}

.p-rowgroup-footer {
  font-weight: 600;
}

.p-paginator .p-dropdown {
  align-items: center !important;
}
