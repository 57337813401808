.ant-input {
  min-height: 24px;
}

/* input hover */
.ant-input:hover {
  border-right-width: 1px !important;
}

/* ariin ongo */
.ant-input-group-addon {
  background-color: white !important;
}

.ant-input-number {
  width: 100% !important;
}

.ant-input-number-input {
  text-align: center !important;
}

.ant-picker {
  min-height: 24px !important;
}
