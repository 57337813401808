#googlemap {
  .autocomplete-dropdown-container {
    width: 30vw;
  }
}

.map-dialog {
  .ant-modal-content {
    height: 100%;
  }
}

.mapSearcher {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  top: 15px;
  justify-content: center;
  .location-search-input {
    height: 2rem;
    width: 30vw;
  }
  .autocomplete-dropdown-container {
    border-radius: 3rem;
  }
}

.gm-style-mtc {
  .button {
    background-color: #000;
  }
}

@media (max-width: 1050px) {
  .mapSearcher {
    top: 50px;
  }
}
