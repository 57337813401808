.ant-carousel {
  font-size: 13px;
  text-align: justify;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 40px !important;
  color: #6c63ff !important;
  transition: all 0.3s;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 45px !important;
  color: #6A63F6 !important;
  transition: all 0.3s;
}

.ant-carousel .slick-prev:hover {
  margin-left: -5px !important;
}

.ant-carousel .slick-next {
  margin-right: 20px;
}

.ant-carousel .slick-dots li button{
  width: 12px;
  height: 12px;
  bottom: 12px;
  border-radius: 100%;
}

.ant-carousel .slick-dots li.slick-active button{
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 3px;
}

.alert-modal {
  max-width: 85vw !important;
  .carousel-dot {
    button {
      background: #0019a2f1 !important;
    }
  }
  .slide-main {
    height: 90%;
    padding: 0 3rem;
    overflow: auto;
    .ant-typography {
      margin-bottom: 8px;
    }
    .slide-title {
      color: blue;
      font-weight: bold;
      text-align: center;
    }
  }
  .attention-text {
    color: #707070;
  }

  .ant-modal-content {
    border-radius: 2rem !important;
  }
}

.rcorners1 {
  border-radius: 1rem;
  background: #6A63F6;
  color: #fff;
  font-family: sans-serif;
  padding: 3px 8px;
  font-size: 15px;
  line-height: 1;
  margin-right: 15px;
  height: 23px;
  align-items: center;
}

.dot {
  height: 25px;
  width: 100%;
  bottom: 18px;
  position: absolute;
  label {
    width: 50%;
    border: 1px dashed $light-cyan !important;
    padding: 4px 36px;
  }
}

.rcorners2 {
  border-radius: 1rem;
  background: #e62800;
  font-family: emoji;
  color: #fff;
  font-weight: bold;
  padding: 0px 8px;
  font-size: 20px;
  line-height: 1.1;
  margin-right: 15px;
  height: 23px;
}

@media (max-width: 500px) {
  .slide-main {
    padding: 0 1rem;
  }
}
