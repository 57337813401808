.ant-list-item {
  padding: 12px;
  &:hover {
    background-color: rgba($info, 0.08);
    cursor: pointer;
  }
}

// .ant-tree-list {
//   .ant-tree-treenode:nth-child(even) {
//     .ant-tree-node-content-wrapper {
//       background: #f8f9fa !important;
//     }
//   }
// }
