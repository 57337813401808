@import '../../../../styles/variables';

.card {
  position: relative;
  display: flex;
  padding: 12px;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px #eeeeeec9;
  margin: 30px 0;
  cursor: pointer;
  &:hover {
    box-shadow: 4px 2px 10px #ddd;
  }
}

.userInfo {
  // background-color: #fcfcfc;
  display: grid;
  justify-items: center;
  h6 {
    color: #deb006;
    :global {
      .anticon {
        font-size: larger;
        margin-right: 4px;
      }
    }
  }
  :global {
    .anticon {
      font-size: 16px;
    }
  }
}

.statusSuccess,
.statusChecking,
.statusCanceled,
.statusTemp {
  font-size: 11px;
  font-style: italic;
}

.statusSuccess {
  color: $success;
}

.statusChecking {
  color: #3eb5ef;
}

.statusTemp {
  color: lightslategrey;
}

.statusCanceled {
  color: $danger;
}

.rejected {
  color: $warning;
}

.value {
  font-weight: 500;
  :global {
    .anticon {
      margin-right: 4px;
    }
  }
}

.label {
  color: gray;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 8px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.item {
  display: grid !important;
  justify-items: center;
  text-align: center;
}

.delete {
  position: absolute;
  display: flex;
  right: -16px;
  top: -16px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 1px -1px 6px 2px #ddd;
}
