.ant-menu {
  background-color: $hover-light;
  padding-bottom: 20px;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $color-grey !important;
}

.ant-menu-item a:hover {
  color: $color-grey !important;
}

.ant-menu-item:hover,
.ant-menu-submenu:hover {
  background-color: $hover-darken;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $color-grey !important;
}

.ant-menu-item-selected {
  color: $color-grey !important;
}

.ant-menu-inline {
  border: none !important;
}

.ant-menu-inline > .ant-menu-item {
  height: 30px !important;
  align-items: center !important;
  display: flex !important;
  padding-left: 24px !important;
  width: auto !important;
  margin: 2px 10px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff !important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: unset !important;
  border-right: 0px solid #e6f7ff !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* submenu selected */
.ant-menu-submenu-selected {
  color: $color-grey !important ;
}

.ant-menu-submenu-title {
  display: flex !important;
  align-items: center;
  .MuiSvgIcon-root {
    color: $light-grey;
    margin-right: 6px;
  }
}

.ant-menu-submenu-title:hover {
  color: grey !important;
}

.ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px !important;
  padding-left: 24px !important;
  width: auto !important;
  margin: 2px 10px !important;
}

.ant-menu-sub.ant-menu-inline {
  padding-left: 20px !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px calc(50% - 4px) !important;
}
