.contactInfo {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 24px;
  margin-top: 8px;
  margin-bottom: 20px;
  .ant-col-12 {
    padding: 0px 24px;
  }
}

.contactInfoError {
  border: 2px solid red;
  color: red;
  p {
    font-weight: 600;
  }
}
