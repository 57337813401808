// global variables
$primary-color: #008eff; // #c1479c; #ff5d5e
$primary-hover: darken($primary-color, 10%);
$secondary-color: #0660b7;
$dark-blue: #004a9f;
$bright-blue: #1a53ff;
$color-grey: #4c4c4c;
$light-blue: #f5f8fe;
$light-cyan: #6dbfd8;
$light-grey: #9d9898;
$hover-light: #fafafa;
$hover-darken: #00000008;
$btn-light: #d2e2fe;
$danger: #f1583c;
$success: #1cbb72;
$success-hover: #40d08d;
$warning: #f3a405;
$info: #01bcd4;
$border-primary-color: #d9d9d9;

$header-height: 50px;
